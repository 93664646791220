.parameter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.9em;
    height: 24px;
}

.parameter--output {
    flex-direction: row-reverse;
}
