.processSelectorItem {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0.5em 0.1em;
    cursor: move;
    user-select: none;
    overflow: hidden;
}

.processSelectorItem__header {
    font-size: 1.1em;
    height: 1.4em;
    border-radius: 1em;
    padding-left: 0.5rem;
    color: white;
    text-shadow: 0px 0px 4px black;
    display: flex;
}

.processSelectorItem__name {
    font-weight: bold;
}

.processSelectorItem:not(.processSelectorItem--subname) .processSelectorItem__name {
    flex-grow: 1;
}

.processSelectorItem__subname {
    margin-left: 0.5em;
    flex-grow: 1;
}

.processSelectorItem__subname::before {
    content: '(';
}

.processSelectorItem__subname::after {
    content: ')';
}

.processSelectorItem__desc {
    font-size: 0.95em;
    padding-left: 0.5rem;
}

.processSelectorItem--system .processSelectorItem__header {
    background-color: #cc8800;
}

.processSelectorItem--user .processSelectorItem__header {
    background-color: #00aaaa;
}

.processSelectorItem--stop .processSelectorItem__header {
    background-color: #ff0000;
}

.processSelectorItem:hover .processSelectorItem__header {
    color: white;
    text-shadow: 0px 0px 4px black, 0px 0px 4px black, 0px 0px 2px black;
}

.processSelectorItem--invalid .processSelectorItem__header::after {
    content: '!';
    color: #f00;
    text-shadow: 0px 0px 4px white;
    margin-right: 0.5em;
}

.processSelectorItem--open .processSelectorItem__header::after {
    content: '\279C';
    margin-right: 0.3em;
}

.processSelectorItem--user:not(.processSelectorItem--open) > .processSelectorItem__header {
    cursor: pointer;
}

.processSelectorItem--hidden {
    display: none;
}

/*
.processSelectorItem__editLink {
    display: none;
    position: absolute;
    right: 0.4em;
    bottom: 0.05em;
    padding: 0.1em;
    background-color: #ccc;
    cursor: pointer;
}

.processSelectorItem:hover .processSelectorItem__editLink {
    display: initial;
}
*/