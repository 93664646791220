.processItem {
    border-radius: 0.5em;
    background-color: #ccc;
    display: inline-flex;
    flex-direction: column;
    padding: 0;
    position: absolute;
    user-select: none;
    border: solid black 1px;
    overflow: hidden;
}

.processItem__header {
    padding: 0.2em 0;
    display: flex;
    align-items: center;
    cursor: move;
    border-bottom: solid black 1px;
    height: 24px;
}

.processItem--focused.processItem--invalid {
    border-color: #a00;
    box-shadow: 0 0 12pt 1pt #a00;
}

.processItem--focused.processItem--invalid .processItem__header {
    border-bottom-color: #a00;
}

.processItem--user .processItem__header {
    background-color: #00aaaa;
}

.processItem--system .processItem__header {
    background-color: #cc8800;
}

.processItem--start .processItem__header {
    background-color: #00aa00;
}

.processItem--stop .processItem__header {
    background-color: #ff3333;
}

.processItem__icon {
    width: 1.3em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.5);
}

.processItem--user .processItem__icon::before {
    content: 'U';
}

.processItem--system .processItem__icon::before {
    content: 'S';
}

.processItem--start .processItem__icon::before {
    content: '\25B6';
    font-size: 0.7em;
}

.processItem--stop .processItem__icon::before {
    content: '\25A0';
}

.processItem__name {
    color: white;
    font-weight: bold;
    margin-right: 1em;
    text-shadow: 0px 0px 4px black;
    flex-grow: 1;
}

.processItem--readonly .processItem__processName {
    color: grey;
}

.processItem--var .processItem__name {
    margin: 0 0.25em 0 0.5em;
}

.processItem__connectors {
    display: flex;
    justify-content: space-between;
}

.processItem__betweenConnectors {
    width: 0.5em;
}

.processItem__outConnectors {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.processItem__connector {
    cursor: pointer;
    font-style: italic;
    height: 24px;
}

.processItem__connector--in, .processItem__connector:last-of-type {
    height: 23px;
}

.processItem__connector--in {
    align-self: flex-start;
}

.processItem__connector::after {
    margin-left: 0.25em;
    width: 1.5em;
    height: 1.5em;
    color: white;
    font-weight: bold;
    font-style: normal;
    text-shadow: 0px 0px 1px black;

    content: '\25B7';
}

.processItem__connector--focused,
.processItem__connector--focused::after,
.processItem__returnPathName--focused,
.parameter--focused.parameter--invalid .parameter__name /* should be in ParameterDisplay.css */ {
    animation-name: throbError;
    animation-duration: 0.75s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

.processItem__connector--connected::after {
    content: '\25B6';
}

.processItem__parameters {
    display: flex;
    justify-content: space-between;
}

.processItem__parameters:not(:empty) {
    padding: 0;
}

.processItem__inputs, .processItem__outputs {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.processItem__inputs:not(:last-child) {
    border-right: solid #666666 1px;
}

.processItem__inputs {
    padding-right: 1em;
}

.processItem__outputs {
    padding-left: 0.5em;
}

.processItem__returnPathName {
    font-style: italic;
    position: relative;
    top: 0.15em;
    right: 0.3em;
}

.processItem__delete {
    width: 1em;
    margin-right: 0.1em;
    opacity: 0.25;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'><line x1='18' y1='6' x2='6' y2='18'></line><line x1='6' y1='6' x2='18' y2='18'></line></svg>");
    align-self: stretch;
}

.processItem:hover .processItem__delete {
    opacity: 0.6;
}

.processItem .processItem__delete:hover {
    opacity: 1;
    cursor: pointer;
}

.processItem__default {
    flex-grow: 1;
    flex-shrink: 1;
}

select.processItem__default {
    background-color: transparent;
}

@keyframes throbError {
    from {
        color: #f00;
        text-shadow: 0px 0px 4px white;
    }
    to {
    }
}