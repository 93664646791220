.valueInput {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0 0.2em;
    text-align: center;
    white-space: pre;

    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px;
}

.valueInput--invalid {
    color: #ff0000;
    text-decoration: underline;
}

.valueInput--noValue:not(:focus) {
    /* "edit" from feather icons */
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-edit'><path d='M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7'></path><path d='M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z'></path></svg>");
    opacity: 0.4;
}

.valueInput--invalid.valueInput--noValue:not(:focus) {
    /* "edit" from feather icons */
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='red' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-edit'><path d='M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7'></path><path d='M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z'></path></svg>");
    opacity: 1;
}