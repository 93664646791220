.processSelector {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    overflow: hidden;
}

.processSelector__scroll {
    overflow-y: scroll;
    flex-grow: 1;
    flex-shrink: 1;
}

.processSelector__filter {
    padding: 0.25em 0.5em;
    border-left: none;
    border-bottom: none;
}