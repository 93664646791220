.tool--variable {
    cursor: default;
}

.tool--variable .tool__icon {
    /* "circle-plus" from feather icons */
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-plus-circle'><circle cx='12' cy='12' r='10'></circle><line x1='12' y1='8' x2='12' y2='16'></line><line x1='8' y1='12' x2='16' y2='12'></line></svg>");
}

.variableSelector {
    cursor: move;
    display: flex;
    align-items: center;
}

.variableSelector__swatch {
    border-radius: 1em;
    width: 1em;
    height: 1em;
    border: solid #ccc 1px;
    margin-right: 0.5em;
}