.signatureEditor {
    display: flex;
    flex-direction: column;
    border: solid black 1px;
    overflow: hidden;
    border-radius: 0.5em;
    background-color: #ccc;
}

.signatureEditor__name {
    color: white;
    font-weight: bold;
    font-size: 1.5em;
    height: 1.4em;
    text-shadow: 0px 0px 4px black;
    background-color: #00aaaa;
    padding: 0.2em 0 0.2em 1.2em;
    display: flex;
    align-items: center;
}

.signatureEditor__name.valueInput--noValue::before {
    content: 'Unnamed';
    opacity: 0.5;
    text-decoration: none;
    text-shadow: none;
    color: black;
    font-weight: normal;
}

.signatureEditor__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.2em 0;
}

.signatureEditor__body + .signatureEditor__body {
    border-top: solid grey 1px;
}

.signatureEditor__description {
    flex-grow: 1;
    align-self: stretch;
    min-width: 8em;
}

.signatureEditor__spacer {
    min-width: 1em;
}