.toolWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
}

.tool {
    display: flex;
    flex-direction: column;
    user-select: none;
    position: relative;
    cursor: pointer;
}

.tool__label {
    font-style: italic;
    height: 1rem;
}

.tool__icon {
    font-size: 2em;
    width: 2rem;
    height: 2rem;
    align-self: center;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 87.5%;
}

.tool--disabled {
    color: #666;
    cursor: not-allowed;
}

.tool--disabled .tool__icon {
    opacity: 0.4;
}

.tool--invalid {
    color: darkred;
    cursor: default;
}

.tool__popout {
    z-index: 5;
    background-color: white;
    padding: 0 1em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    border: solid grey 1px;
    display: none;
    border-top-width: 0px;
    position: absolute;
    top: 100%;
}

.tool:hover > .tool__popout, .tool:active .tool__popout {
    display: block;
}

.tool__icon::before {
    position: relative;
    top: -0.125em;
}

.tool__popoutItem {
    padding: 0.5em 0;
}

.tool__popoutItem:hover {
    text-decoration: underline;
}