.processFolder {
    margin-top: 1em;
}

.processFolder--closed > .processFolder__content {
    display: none;
}

.processFolder--hidden {
    display: none;
}

.processFolder__header {
    font-weight: bold;
    cursor: pointer;
    user-select: none;

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: #ccc;
    margin: 0.5em 0;
    height: 1.75em;
}

.processFolder__headerText {
    flex-grow: 1;
    padding-left: 0.6rem;
}

.processFolder__header::after {
    color: #666;
    position: relative;
    top: -0.1em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.9em;
    font-size: 1.5em;
}

.processFolder--open > .processFolder__header::after {
    content: '-';
}

.processFolder--closed > .processFolder__header::after {
    content: '+';
}

.processFolder__header:hover::after {
    text-shadow: 0px 0px 4px white, 0px 0px 4px white, 0px 0px 4px white;
}