.connector {
    cursor: pointer;
    position: relative;
    width: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}

.connector::before {
    content: '\25C7';
    position: relative;
    top: 0.1em;
    text-shadow: 0px 0px 4px white;
}

.connector--connected::before {
    content: '\25C6';
}

.connector--fixed::before {
    content: '\25C8';
}