.workspaceEditor {
    display: grid;
    grid-template-columns: 12em 1fr;
    grid-template-rows: 3em 3em 1fr;
    grid-template-areas:
        "header header"
        "addnew content"
        "sidebar content";
}

@media (min-width: 1024px) {
    .workspaceEditor {
        grid-template-rows: 3em 1fr;
        grid-template-areas:
            "addnew header"
            "sidebar content";
    }
}

.workspaceEditor * {
    box-sizing: border-box;
}

.workspaceEditor__sidebar {
    grid-area: sidebar;
}

.workspaceEditor__toolbar {
    grid-area: header;
}

.workspaceEditor__content {
    grid-area: content;
}

.workspaceEditor__addNew {
    grid-area: addnew;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: #ccc;
    margin: 0.5em 1.5em 0.5em 0.5em;
    border-radius: 1em;
    cursor: pointer;
}

.workspaceEditor__header {
    grid-area: header;
    text-align: center;
    font-size: 2em;
}