.processEditor {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.processEditor__footer {
    margin-top: 2em;
    display: flex;
    align-items: flex-start;
}

.processEditor__button + .processEditor__button {
    margin-left: 1em;
}